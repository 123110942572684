<template>
  <div class="text-center">
    <v-card>
      <v-card-title primary-title> Filtros </v-card-title>
      <v-card-text v-if="headers_filter">
        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              v-if="service"
              dense
              label="Tipo da OS"
              item-text="name"
              item-value="id"
              multiple
              flat
              chips
              clearable
              :items="service"
              @click:clear="filters.service.ref_type = null"
              v-model="filters.service.ref_type"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <div v-for="header in headers_filter" :key="header.name">
          <v-autocomplete
            flat
            multiple
            dense
            chips
            clearable
            outlined
            :label="header.name"
            item-text="name"
            item-value="id"
            @click:clear="filters[header.filter]"
            :items="columnValueList(header.data)"
            v-model="filters.filter[header.filter]"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>
                  {{ item.name }}
                </span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ filters.filter[header.filter].length - 3 }})
              </span>
            </template>
          </v-autocomplete>
        </div>
        <v-row>
          <v-col>
            <v-menu
              v-model="from"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  outlined
                  label="Data de agendamento inicial"
                  hint="dia/mês/ano"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  dense
                  v-facade="'##/##/####'"
                  v-on="on"
                  clearable
                  @click:clear="filters.date_scheduling.from = null"
                  :value="formatDateFilter(filters.date_scheduling.from)"
                  autocomplete="off"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.date_scheduling.from"
                no-title
                @input="from = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="to"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  outlined
                  dense
                  label="Data de agendamento Final"
                  hint="dia/mês/ano"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-facade="'##/##/####'"
                  v-on="on"
                  :value="formatDateFilter(filters.date_scheduling.to)"
                  autocomplete="off"
                  clearable
                  @click:clear="filters.date_scheduling.to = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.date_scheduling.to"
                no-title
                @input="to = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="clearFilters()"> Limpar </v-btn>
        <v-btn color="primary" text @click="apply()"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import formatDate from "../../mixins/formatDate";
import { dateFormat } from "../../utils/helpers/dateHelper";

export default {
  mixins: [formatDate],

  data() {
    return {
      fav: true,
      message: false,
      hints: true,
      from: false,
      to: false,
      service: [],
      items: {
        area: [],

        period: [{ name: "Manhã" }, { name: "Tarde" }, { name: "Noite" }]
      },
      filters: {
        filter: {},
        service: {},
        date_scheduling: {
          from: null,
          to: null
        }
      },

      headers_filter: [
        { name: "Periodo", data: "period", filter: "period" },
        { name: "Área", data: "area", filter: "area" }
      ]
    };
  },

  created() {
    this.getAreas();
    this.getService();
  },
  mounted() {
    if (localStorage.getItem("filters_offer") !== null) {
      this.filter_enable = true;
      let criterias = JSON.parse(window.localStorage.getItem("filters_offer"));

      this.filters = Object.assign({}, criterias);

      console.log(this.filters);
    }
  },

  methods: {
    async getService() {
      const response = await this.$http.get("/service-items");
      this.service = response.data;
    },
    formatDateFilter(date) {
      if (date) return dateFormat(date);
    },
    async getAreas() {
      try {
        const response = await this.$http.get("/area");
        this.items.area = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    columnValueList(val) {
      return this.items[val];
    },
    async clearFilters() {
      this.filters = Object.assign(
        {},
        {
          filter: {},
          service: {
            ref_type: null
          },
          date_scheduling: {
            from: null,
            to: null
          }
        }
      );

      localStorage.removeItem("filters_offer");
      this.$emit("update-badge", false);
      this.$emit("update");
    },

    async getServiceItem() {
      const response = await this.$http.get("/service-items");
      this.service = response.data;
    },

    async apply() {
      let criteria = {
        filter: this.filters.filter,
        date_scheduling: this.filters.date_scheduling,
        service: this.filters.service
      };

      const response = await this.$http.post("/offer/filter", criteria);

      this.$emit("update-table", response.data);
      this.$emit("update-badge", true);

      localStorage.setItem("filters_offer", JSON.stringify(criteria));
    }
  }
};
</script>

<style></style>
