var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.profile)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"teal",attrs:{"dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Ofertas ")]),_c('v-spacer'),(_vm.isAdmin || _vm.canEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 mb-2",attrs:{"color":"","icon":"","x-large":"","to":"/oferta/adicionar"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,3800121425)},[_vm._v(" Adicionar oferta ")]):_vm._e()],1),_c('v-card-title',{staticClass:"mb-n5 mt-n3 grey lighten-5"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":800,"right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[(_vm.filter_enable)?_c('v-badge',{attrs:{"dot":"","overlap":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,3554622152),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('filterOffer',{attrs:{"menu":_vm.filter_offer},on:{"update-table":_vm.updateTable,"update":function($event){return _vm.offerFilter()},"update-badge":_vm.filter_badge}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.items)?_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.is_disponible",fn:function(ref){
var item = ref.item;
return [(item.is_disponible)?_c('v-chip',{attrs:{"color":"primary","label":""}},[_vm._v("Disponivel")]):_c('v-chip',{attrs:{"label":"","color":"error"}},[_vm._v("Indisponivel")])]}},{key:"item.data_scheduling",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_scheduling))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_disponible && _vm.canEdit)?_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}],null,false,1363322628)}):_vm._e()],1),(_vm.editDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editOffer',{attrs:{"editOffer":_vm.offer},on:{"update":function($event){return _vm.getOffers()},"close-dialog":function($event){_vm.editDialog = !_vm.editDialog}}})],1):_vm._e(),(_vm.removeDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('removeOffer',{attrs:{"removeOffer":_vm.offer},on:{"update":function($event){return _vm.getOffers()},"close-dialog":function($event){_vm.removeDialog = !_vm.removeDialog}}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }