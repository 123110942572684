<template>
  <v-card>
    <v-toolbar dense flat class="teal" dark>
      <v-toolbar-title>Editar oferta</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-container v-if="area">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="ref_type"
              :items="service"
              outlined
              return-object
              item-text="name"
              item-value="id"
              label="Ordem de serviço"
              required
            >
            </v-select>
            <v-select
              :items="area"
              v-if="area"
              v-model="offer.id_area"
              item-text="name"
              item-value="id"
              outlined
              label="Área"
            ></v-select>
            <v-select
              v-model="offer.period"
              label="Período"
              :items="period"
              required
              outlined
            ></v-select>
            <v-menu
              ref="menu1"
              v-model="show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  label="Data do agendamento"
                  hint="dia/mês/ano"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :value="dateformat(offer.data_scheduling)"
                  autocomplete="off"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="offer.data_scheduling"
                no-title
                :min="dateMin"
                @input="show = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn color="success" outlined @click="save()"> Salvar </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="closeDialog()"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateNow, dateFormat } from "../../utils/helpers/dateHelper";

export default {
  name: "editClient",
  props: ["editOffer"],

  data() {
    return {
      period: ["Manhã", "Tarde", "Noite"],
      service: [],
      ref_type: {
        id: this.editOffer.ref_type
      },
      offer: Object.assign({}, this.editOffer),
      area: null,
      show: false,
      name: "teste",
      dateMin: dateNow()
    };
  },
  created() {
    this.getAreas();
    this.getServiceItems();
  },
  watch: {
    ref_type: function(val) {
      console.log(val);
      this.offer.os_type = val.name;
      this.offer.ref_type = val.id;
    }
  },
  methods: {
    dateformat(date) {
      return dateFormat(date);
    },
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async getServiceItems() {
      try {
        const response = await this.$http.get("/service-items");
        this.service = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAreas() {
      try {
        const response = await this.$http.get("/area", this.offer);
        this.area = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        const response = await this.$http.put("/offer", {
          id: this.offer.id,
          tipo_os: this.offer.os_type,
          ref_tipo: this.offer.ref_type,
          data_agendamento: this.offer.data_scheduling,
          periodo: this.offer.period,
          area: this.offer.id_area,
          usuario: "1"
        });
        if (response) this.$toast.success("Oferta editada com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
      }
    }
  }
};
</script>

<style></style>
