var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Filtros ")]),(_vm.headers_filter)?_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.service)?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Tipo da OS","item-text":"name","item-value":"id","multiple":"","flat":"","chips":"","clearable":"","items":_vm.service},on:{"click:clear":function($event){_vm.filters.service.ref_type = null}},model:{value:(_vm.filters.service.ref_type),callback:function ($$v) {_vm.$set(_vm.filters.service, "ref_type", $$v)},expression:"filters.service.ref_type"}}):_vm._e()],1)],1),_vm._l((_vm.headers_filter),function(header){return _c('div',{key:header.name},[_c('v-autocomplete',{attrs:{"flat":"","multiple":"","dense":"","chips":"","clearable":"","outlined":"","label":header.name,"item-text":"name","item-value":"id","items":_vm.columnValueList(header.data)},on:{"click:clear":function($event){_vm.filters[header.filter]}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('v-chip',[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.filters.filter[header.filter].length - 3)+") ")]):_vm._e()]}}],null,true),model:{value:(_vm.filters.filter[header.filter]),callback:function ($$v) {_vm.$set(_vm.filters.filter, header.filter, $$v)},expression:"filters.filter[header.filter]"}})],1)}),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"facade",rawName:"v-facade",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"required":"","outlined":"","label":"Data de agendamento inicial","hint":"dia/mês/ano","persistent-hint":"","prepend-inner-icon":"mdi-calendar","dense":"","clearable":"","value":_vm.formatDateFilter(_vm.filters.date_scheduling.from),"autocomplete":"off"},on:{"click:clear":function($event){_vm.filters.date_scheduling.from = null}}},'v-text-field',attrs,false),on))]}}],null,false,937753955),model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.from = false}},model:{value:(_vm.filters.date_scheduling.from),callback:function ($$v) {_vm.$set(_vm.filters.date_scheduling, "from", $$v)},expression:"filters.date_scheduling.from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"facade",rawName:"v-facade",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"required":"","outlined":"","dense":"","label":"Data de agendamento Final","hint":"dia/mês/ano","persistent-hint":"","prepend-inner-icon":"mdi-calendar","value":_vm.formatDateFilter(_vm.filters.date_scheduling.to),"autocomplete":"off","clearable":""},on:{"click:clear":function($event){_vm.filters.date_scheduling.to = null}}},'v-text-field',attrs,false),on))]}}],null,false,195422022),model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.to = false}},model:{value:(_vm.filters.date_scheduling.to),callback:function ($$v) {_vm.$set(_vm.filters.date_scheduling, "to", $$v)},expression:"filters.date_scheduling.to"}})],1)],1)],1)],2):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.clearFilters()}}},[_vm._v(" Limpar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.apply()}}},[_vm._v(" Aplicar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }