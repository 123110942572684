import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = {
  filters: {
    formatDate(value) {
      return dayjs(value)
        .tz("America/Fortaleza")
        .local()
        .format("DD/MM/YYYY");
    },
    formatDateTime(value) {
      return dayjs(value)
        .tz("America/Fortaleza")
        .local()
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }
};

export default formatDate;
