<template>
  <v-card flat>
    <v-toolbar class="teal" dark>
      <v-toolbar-title>Remover oferta</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>

    <v-card-title class="justify-center">
      Você deseja remover esta oferta?
    </v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> a oferta será removida</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn color="error" text @click="closeDialog()">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-spacer></v-spacer>

      <v-btn color="success" text @click="deleteEvent()"
        ><v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditEvent",
  props: ["removeOffer"],

  data() {
    return {
      offer: {
        id: this.removeOffer.id
      }
    };
  },

  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async deleteEvent() {
      try {
        const response = await this.$http.delete(`/offer/${this.offer.id}`);
        if (response) this.$toast.success("Oferta removida com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
        this.$emit("close-dialog");
      }
    }
  }
};
</script>

<style></style>
