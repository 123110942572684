<template>
  <v-container v-if="profile" fluid>
    <v-card flat>
      <v-toolbar dense flat class="teal" dark>
        <v-toolbar-title>Ofertas </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-tooltip v-if="isAdmin || canEdit" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color=""
              v-on="on"
              v-bind="attrs"
              class="mt-2 mb-2"
              icon
              x-large
              to="/oferta/adicionar"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          Adicionar oferta
        </v-tooltip>
      </v-toolbar>

      <v-card-title class="mb-n5 mt-n3 grey lighten-5">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="800"
          right
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <v-badge dot overlap color="red" v-if="filter_enable">
                <v-icon>mdi-filter</v-icon>
              </v-badge>
              <v-icon v-else>mdi-filter</v-icon>
            </v-btn>
          </template>
          <filterOffer
            v-on:update-table="updateTable"
            v-on:update="offerFilter()"
            v-on:update-badge="filter_badge"
            :menu="filter_offer"
          />
        </v-menu>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        class="elevation-2"
      >
        <template v-slot:item.is_disponible="{ item }">
          <v-chip v-if="item.is_disponible" color="primary" label
            >Disponivel</v-chip
          >
          <v-chip v-else label color="error">Indisponivel</v-chip>
        </template>
        <template v-slot:item.data_scheduling="{ item }">
          {{ item.data_scheduling | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="item.is_disponible && canEdit">
            <v-btn color="success" icon class="mr-2">
              <v-icon @click="edit(item)">mdi-square-edit-outline</v-icon>
            </v-btn>

            <v-btn color="error" icon class="mr-2">
              <v-icon @click="remove(item)">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editOffer
        :editOffer="offer"
        v-on:update="getOffers()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeOffer
        :removeOffer="offer"
        v-on:update="getOffers()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import editOffer from "./edit";
import removeOffer from "./remove";
import filterOffer from "./filterOffer";
import dateFormat from "../../mixins/formatDate";
import Authz from "../../mixins/authorized";
export default {
  mixins: [dateFormat, Authz],
  components: {
    editOffer,
    removeOffer,
    filterOffer
  },

  data() {
    return {
      items: "",
      editDialog: false,
      removeDialog: false,
      search: null,
      menu: false,
      filter_enable: false,
      filter_offer: false,

      offer: "",
      headers: [
        { text: "Ordem de Serviço", value: "os_type" },
        { text: "Área", value: "area" },
        { text: "Data", value: "data_scheduling" },
        { text: "Período", value: "period" },
        { text: "Disponibilidade", value: "is_disponible" },
        { text: "Ações", value: "actions" }
      ]
    };
  },

  created() {
    this.getOffers();
    this.offerFilter();
  },

  methods: {
    async updateTable(val) {
      this.items = val;
    },
    filter_badge(val) {
      if (val) {
        return (this.filter_enable = true);
      }
      return (this.filter_enable = false);
    },
    async changeBadge(value) {
      if (value) return "primary";
    },
    async getOffers() {
      const response = await this.$http.get("/offers");
      this.items = response.data;
    },
    async edit(offerSelected) {
      this.editDialog = true;
      this.offer = offerSelected;
    },
    async remove(offerSelected) {
      this.removeDialog = true;
      this.offer = offerSelected;
    },
    async offerFilter() {
      if (localStorage.getItem("filters_offer") !== null) {
        this.filter_enable = true;
        let criterias = JSON.parse(
          window.localStorage.getItem("filters_offer")
        );
        const response = await this.$http.post("/offer/filter", criterias);
        this.items = response.data;
      } else {
        this.getOffers();
      }
    }
  }
};
</script>
<style></style>
